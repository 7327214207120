<template>
  <div class="mall-goods-type">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增分类</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="排列序号" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.sortWeight || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="分类名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsTypeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="分类名称" prop="goodsTypeName">
            <el-input v-model.trim="params.goodsTypeName" autocomplete="off" maxlength="10" placeholder="请填写分类名称"
              clearable></el-input>
          </el-form-item>
          <el-form-item label="排列序号" prop="sortWeight">
            <el-input v-model.trim="params.sortWeight" autocomplete="off" maxlength="5" placeholder="请填写排列序号"
              oninput="value=value.replace(/[^\d]/g,'')" clearable>
            </el-input>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getGoodsTypeList,
  getGoodsTypeAdd,
  getGoodsTypeEdit,
  getGoodsTypeDel,
} from "@/api/mall/mall";
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      // table表格 
      tableData: [],//表格数据
      tableParams: {
        merchantId: "",//商户ID
        shopId: "",//店铺ID
      },
      // 弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      // from表单
      rules,
      params: {
        goodsTypeName: "",
        sortWeight: "",
      },
    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getGoodsTypeList();// 【请求】表格数据
  },
  methods: {
    // 【请求】点单商品分类列表
    getGoodsTypeList() {
      let data = this.tableParams;
      getGoodsTypeList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data;
        }
      });
    },

    // 【请求】新增商品分类
    getGoodsTypeAdd() {
      let data = this.params;
      getGoodsTypeAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getGoodsTypeList();// 【请求】表格数据
        }
      });
    },

    // 【请求】编辑
    getGoodsTypeEdit() {
      let data = this.params;
      getGoodsTypeEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getGoodsTypeList();// 【请求】表格数据
        }
      });
    },


    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增分类";
      this.params = {
        shopId: this.tableParams.shopId,
        merchantId: this.tableParams.merchantId,
        goodsTypeName: "",
        sortWeight: "",
      }
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑分类";
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】删除
    onDel(row) {
      let data = row;
      this.$confirm('你确定要删除该商品分类吗?', '删除商品分类', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getGoodsTypeDel(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getGoodsTypeList();
          }
        })
      });
    },


    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.goodsTypeId) {
            this.getGoodsTypeEdit();
          } else {
            this.getGoodsTypeAdd();
          }
        } else {
          return false;
        }
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.mall-goods-type {
  padding: .15rem;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>